<template>
  <div class="main-container">
    <!-- <div class="park-name">龙泉巷停车场</div> -->
    <div class="license-content">
      <el-input v-model="license" placeholder="请输入车牌号" />
    </div>
    <div>
      <el-button type="primary" @click="queryCostInfo" :disabled="license === ''">查询停车费</el-button>
    </div>
    <el-divider v-if="info?.orderAmt != undefined" />
    <div v-if="info?.orderAmt != undefined">
      <div class="info-content">
        <div>停车场：</div>
        <div>{{ info.parkName }}</div>
      </div>
      <div class="info-content">
        <div>停车时长：</div>
        <div>{{ info.durationDesc }}</div>
      </div>
      <div class="info-content">
        <div>入场时间：</div>
        <div>{{ info.startTime }}</div>
      </div>
      <div class="info-content">
        <div>停车费用：</div>
        <div>￥{{ info.orderAmt / 100 }}</div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref } from 'vue';
import { getAction } from '@/utils/manage';
import md5 from 'js-md5';

const key = 'HMDC1578GFAsvc'
const license = ref('京B1234')
const info = ref()

function queryCostInfo() {
  const currentDate = timeFormat()
  const licenseUpper = license.value.toUpperCase()
  const param = {
    sign: md5(key + currentDate + licenseUpper),
    ts: currentDate,
    plateNumber: licenseUpper
  }
  console.log(param)
  getAction('/park/bill/temp', param).then(res => {
    if (res.code === 200) {
      info.value = res.data
    }
  })
}

function timeFormat() {
  let dateTime = new Date()
  // 获取年份
  let Y = dateTime.getFullYear()
  //获取当前年的月份 月份要 + 1 （0代表1月）date.getMonth() + 1
  let M = dateTime.getMonth() + 1 < 10 ? '0' + (dateTime.getMonth() + 1) : (dateTime.getMonth() + 1)
  //获取当前日（1 - 31）
  let D = dateTime.getDate() < 10 ? '0' + (dateTime.getDate()) : (dateTime.getDate())
  //获取当前小时（0-23）
  let h = dateTime.getHours() < 10 ? ('0' + dateTime.getHours()) : (dateTime.getHours());
  //获取当前分钟（0-59）
  let m = dateTime.getMinutes() < 10 ? ('0' + dateTime.getMinutes()) : (dateTime.getMinutes());
  //获取当前秒数（0-59）
  let s = dateTime.getSeconds() < 10 ? ('0' + dateTime.getSeconds()) : dateTime.getSeconds();
  return (Y + M + D + h + m + s)
}
</script>

<style scoped lang="scss">
.main-container {
  padding: 20px;
}

.park-name {
  font-weight: 600;
  font-size: 18px;
}

.license-content {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
}

.info-content{
  display: flex;
  flex-direction: row;
}
</style>