<template>
  <div>
    <el-result icon="success" :title="title"></el-result>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';

const route = useRoute()
const title = ref('')
onMounted(() => {
  const message = route.query.message
  title.value = message? message : '支付成功'
})
</script>

<style scoped lang="scss"></style>