import axios from 'axios';
// import { getToken } from './cache';
// import { Message } from 'element-ui';
// import store from '@/store';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
});

// request interceptor
service.interceptors.request.use(
  config => {
    // const token = getToken();
    // if (token) {
      // config.headers['Authorization'] = 'Bearer ' + token; // 让每个请求携带自定义 token
    // }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code && res.code != 200) {
      // Message({
      //   message: res.mesg || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // });
      return res;
    } else {
      return res;
    }
  },
  error => {
    if (error.response) {
      // const data = error.response.data;
      // const token = getToken();
      switch (error.response.status) {
        case 500:
          // if (token && data.message == '登陆凭证失效，请重新登陆') {
          //   Message({
          //     message: '很抱歉，登录已过期，请重新登录',
          //     type: 'warning',
          //     duration: 5 * 1000
          //   });
          //   setTimeout(() => {
          //     store.dispatch('user/resetToken').then(() => {
          //       location.reload();
          //     });
          //   }, 2000);
          // }
          break;
        case 404:
          // notification.error({ message: '系统提示', description:'很抱歉，资源未找到!',duration: 4})
          break;
        case 504:
          // Message.error({ message: '系统提示,网络超时', type: 'error' });
          break;
        default:
          // Message.error({
          //   message: '系统提示：' + data.mesg
          // });
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
