import { createStore } from 'vuex'

export default createStore({
  state: {
    license: ''
  },
  getters: {
  },
  mutations: {
    setLicense(state, val) {
      state.license = val
    }
  },
  actions: {
  },
  modules: {
  }
})
