<template>
  <div class="keyboard-content" id="keyboardContent" @click.stop="" @touchmove="stopSelect">
    <div @touchmove="stopSelect">
      <template v-for="info of getKeysByLine(0)" :key="info.name">
        <div @touchmove="stopSelect">
          <div class="tip">{{ info.name }}</div>
          <div class="input-text" @touchstart="touchstart" @touchend="touchend($event, info.name)">{{ info.name
          }}</div>
        </div>
      </template>
    </div>
    <div>
      <template v-for="info of getKeysByLine(1)" :key="info.name">
        <div>
          <div class="tip">{{ info.name }}</div>
          <div class="input-text" @touchstart="touchstart" @touchend="touchend($event, info.name)">{{ info.name
          }}</div>
        </div>
      </template>
    </div>
    <div>
      <template v-for="info of getKeysByLine(2)" :key="info.name">
        <div>
          <div class="tip">{{ info.name }}</div>
          <div class="input-text" @touchstart="touchstart" @touchend="touchend($event, info.name)">{{ info.name
          }}</div>
        </div>
      </template>
    </div>
    <div>
      <template v-for="info of getKeysByLine(3)" :key="info.name">
        <div class="input-check" v-if="info.name === 'check'" @click="checkTapped">
          <el-icon>
            <Check />
          </el-icon>
        </div>
        <div v-else>
          <div class="tip">{{ info.name }}</div>
          <div class="input-text" @touchstart="touchstart" @touchend="touchend($event, info.name)">{{ info.name
          }}</div>
        </div>

      </template>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'

function getKeysByLine(num) {
  const inputInfoList = [
    { name: '京', row: 0 },
    { name: '津', row: 0 },
    { name: '冀', row: 0 },
    { name: '晋', row: 0 },
    { name: '蒙', row: 0 },
    { name: '辽', row: 0 },
    { name: '吉', row: 0 },
    { name: '黑', row: 0 },
    { name: '沪', row: 1 },
    { name: '苏', row: 1 },
    { name: '浙', row: 1 },
    { name: '皖', row: 1 },
    { name: '闽', row: 1 },
    { name: '赣', row: 1 },
    { name: '鲁', row: 1 },
    { name: '豫', row: 1 },
    { name: '鄂', row: 2 },
    { name: '湘', row: 2 },
    { name: '粤', row: 2 },
    { name: '桂', row: 2 },
    { name: '琼', row: 2 },
    { name: '川', row: 2 },
    { name: '贵', row: 2 },
    { name: '云', row: 2 },
    { name: '渝', row: 3 },
    { name: '藏', row: 3 },
    { name: '陕', row: 3 },
    { name: '甘', row: 3 },
    { name: '青', row: 3 },
    { name: '宁', row: 3 },
    { name: '新', row: 3 },
    { name: 'check', row: 3 },
  ]
  return inputInfoList.filter(item => item.row === num)
}

const emit = defineEmits(['check-tapped', 'key-tapped'])
function checkTapped() {
  emit('check-tapped')
}

function touchstart(event) {
  event.stopPropagation();
  event.preventDefault();
  const target = event.target
  const tipEle = target.parentElement.children[0]
  tipEle.style.top = (target.offsetTop - 60) + 'px'
  tipEle.style.left = (target.offsetLeft - 3) + 'px'
  tipEle.style.display = 'block'
}

function touchend(event, val) {
  const target = event.target
  const tipEle = target.parentElement.children[0]
  tipEle.style.display = 'none'
  emit('key-tapped', val)
}

function stopSelect(event) {
  event.preventDefault();
  return false;
}
</script>

<style scoped lang="scss">
.keyboard-content {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #EBEEF3;
  border-top: 1px solid rgba($color: lightgray, $alpha: 0.5);
  padding: 10px 10px 15px 10px;

  >div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 5px;

    >div {
      .input-text {
        width: 35px;
        height: 45px;
        line-height: 45px;
        border-radius: 5px;
        background-color: white;
        font-size: 20px;
      }

      .tip {
        width: 43px;
        height: 55px;
        line-height: 55px;
        border: 1px solid rgba($color: lightgray, $alpha: 0.3);
        box-shadow: 1px 1px 10px rgba($color: lightgray, $alpha: 1);
        display: none;
        position: absolute;
        border-radius: 5px;
        background-color: white;
        font-size: 20px;
      }
    }

    .input-check {
      width: 35px;
      height: 45px;
      line-height: 45px;
      font-size: 22px;
      padding-top: 3px;
    }
  }
}
</style>