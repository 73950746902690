<template>
  <div class="main-container" id="costController" @click.prevent="dismissKeyboard">
    <div class="content-container">
      <div class="license-container">
        <div>无牌车登记</div>
        <div class="license-input-content" id="licenseContent" @click.stop="">
          <template v-for="(item, index) of licenseList" :key="item.ref">
            <div v-if="index == 0" class="license-area" :class="dispLicenseInput ? 'license-area-focus' : ''"
              @click="dispLicenseKeyboard">{{ item.value }}</div>

            <el-input v-else-if="index === 1" maxlength="1" :disabled="dispCost" v-model="item.value"
              @focus="dismissKeyboard" @input="inputChange(index)" :ref="(ele) => setItemRef(ele, item.ref)" />

            <el-input v-else class="license-num" :maxlength="getMaxLength()" :disabled="dispCost" v-model="item.value"
              @focus="dismissKeyboard" @input="inputChange(index)" :ref="(ele) => setItemRef(ele, item.ref)" />

            <div v-if="index == 1" style="font-weight: 600;">·</div>
          </template>
        </div>

        <div class="license-tool">
          <el-switch v-model="isElectricity" v-if="!dispCost"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #409eff" class="mb-2" active-text="新能源"
            inactive-text="燃油" @change="energyTypeChange" />
          <el-button size="large" type="primary" link v-if="dispCost" @click="dispCost = false">修改</el-button>
        </div>
      </div>

      <div class="finish-container" v-if="!dispCost">
        <el-button size="large" type="primary" @click="finishInput">完成</el-button>
      </div>
    </div>
    <div class="footer-container">
      <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <p style=";height:20px;line-height:20px; color:#939393;">沪ICP备06011778号-1
        </p>
      </a>
    </div>
    <license-keyboard class="license-input" v-show="dispLicenseInput" @key-tapped="keyboardTapped"
      @check-tapped="keyboardChecked" />
  </div>
</template>

<script setup>
import { getAction } from '@/utils/manage';
import { ref, reactive, nextTick, onMounted } from 'vue';
import { ElMessage, ElLoading } from 'element-plus'

let parkIdParam = '1'
const dispLicenseInput = ref(false)
const dispCost = ref(false);
const licenseList = reactive([
  { value: '浙', ref: 'License0' },
  { value: 'C', ref: 'License1' },
  { value: '', ref: 'License2' },
])
const isElectricity = ref(true)
function energyTypeChange() {
  licenseList[2].value = ''
}
function dispLicenseKeyboard() {
  if (dispCost.value) {
    return
  }
  document.activeElement.blur();
  dispLicenseInput.value = true;
}
function keyboardTapped(val) {
  licenseList[0].value = val;
}
function keyboardChecked() {
  dismissKeyboard();
  autoFocusNext()
}
function dismissKeyboard() {
  dispLicenseInput.value = false;
}
function inputChange(i) {
  licenseList[i].value = licenseList[i].value.toUpperCase()
  autoFocusNext()
}
function autoFocusNext() {
  let emptyIndex = -1;
  for (const index in licenseList) {
    const val = licenseList[index].value;
    if (val === '' || val === undefined) {
      emptyIndex = parseInt(index);
      break;
    }
  }
  const item = licenseList[emptyIndex]
  if (emptyIndex === 0) {
    document.activeElement.blur();
    dispLicenseInput.value = true;
  } else {
    if (item) {
      nextTick(() => {
        item[item.ref].focus()
      })
    }
  }
}

function setItemRef(ele, key) {
  if (ele) {
    licenseList.map(item => {
      if (item.ref === key) {
        item[key] = ele
      }
    })
  }
}

function getMaxLength() {
  return isElectricity.value ? 6 : 5
}

function finishInput() {
  const license = licenseList.map(item => item.value).join('')
  const licenseLength = isElectricity.value ? 8 : 7
  if (license.length < licenseLength) {
    ElMessage({
      showClose: false,
      message: '请输入完整的车牌号。',
      type: 'error',
      customClass: 'cost-massage'
    })
    return;
  }
  const loading = ElLoading.service({
    lock: true,
    background: 'rgba(0, 0, 0, 0.7)',
  })
  getAction(`/c/c/${parkIdParam}`, { license: license }).then(res => {
    loading.close()
    if (res.code === 200) {
      ElMessage({
        showClose: false,
        message: '成功',
        type: 'success'
      })
    } else {
      ElMessage({
        showClose: false,
        message: res.message,
        type: 'error',
        customClass: 'cost-massage'
      })
    }
  })
}

onMounted(() => {
  const geturl = window.location.href;
  const urlParam = geturl.split('?');
  if (urlParam.length > 1) {
    const param = urlParam[1];
    const params = new URLSearchParams(param);
    parkIdParam = params.get('parkId');
  }
})
</script>

<style lang="scss" scoped>
.main-container {
  background: #f7f7f7;
  height: 100vh;

  .content-container {
    height: calc(100% - 20px);

    .license-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 20px 20px 10px 20px;
      border: 1px solid #d3d3d3;
      background-color: white;

      >div:first-child {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .license-input-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .license-area {
          width: 38px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          font-size: 18px;
          // border: 1px solid var(--el-border-color);
          box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
          border-radius: var(--el-border-radius-base);
        }

        .license-area-focus {
          box-shadow: 0 0 0 1px var(--el-color-primary) inset;
        }

        .el-input {
          width: 38px;
          height: 38px;
          text-align: center;
          font-size: 18px;
        }

        .license-num {
          width: calc(100% - 38px - 38px - 5px);

          :deep(.el-input__inner) {
            text-align: left;
            font-size: 18px;
            letter-spacing: 1.5em;
          }
        }

        .el-input.is-disabled {
          :deep(.el-input__wrapper) {
            box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
            background-color: var(--el-input-bg-color, var(--el-fill-color-blank));

            .el-input__inner {
              color: var(--el-text-color-primary);
              -webkit-text-fill-color: var(--el-text-color-primary);
            }
          }
        }

        :deep(.el-input__inner) {
          text-align: center;
        }
      }

      .el-button {
        margin-left: 10px;
      }
    }

    .license-tool {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    .finish-container {
      margin-top: 10px;
      padding: 0 20px;

      .el-button {
        width: 90vw;
      }
    }

    .cost-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0;
      border: 1px solid lightgray;
      background-color: white;
      margin-top: 20px;

      .cost-num {
        font-weight: 900;
        font-size: 28px;
        margin-top: 20px;

      }

      .divide-line {
        width: 90%;
        height: 1px;
        background-color: lightgray;
        margin: 20px 0;
      }

      .cost-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 90%;

        .detail-container {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    .pay-container {
      margin-top: 20px;
      padding: 0 20px;

      .el-button {
        width: 90vw;
      }
    }
  }

  .license-table {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    height: calc(100vh - 153px - 10px - 40px - 12px - 20px);

    .el-table {
      width: 90vw;
      height: 100%;
    }
  }

  .footer-container {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
  }
}
</style>
