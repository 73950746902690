<template>
  <div class="main-container" id="costController" @click.prevent="dismissKeyboard">
    <div class="content-container">
      <div class="license-container">
        <div>请确认车牌号码</div>
        <div class="license-input-content" id="licenseContent" @click.stop="">
          <template v-for="(item, index) of licenseList" :key="item.ref">
            <div v-if="index == 0" class="license-area" :class="dispLicenseInput ? 'license-area-focus' : ''"
              @click="dispLicenseKeyboard">{{ item.value }}</div>

            <el-input v-else-if="index === 1" maxlength="1" :disabled="dispCost" v-model="item.value"
              @focus="dismissKeyboard" @input="inputChange(index)" :ref="(ele) => setItemRef(ele, item.ref)" />

            <el-input v-else class="license-num" :maxlength="getMaxLength()" :disabled="dispCost" v-model="item.value"
              @focus="dismissKeyboard" @input="inputChange(index)" :ref="(ele) => setItemRef(ele, item.ref)" />

            <div v-if="index == 1" style="font-weight: 600;">·</div>
          </template>
        </div>

        <div class="license-tool">
          <el-switch v-model="isElectricity" v-if="!dispCost"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #409eff" class="mb-2" active-text="新能源"
            inactive-text="燃油" @change="energyTypeChange" />
          <el-button size="large" type="primary" link v-if="dispCost" @click="dispCost = false">修改</el-button>
        </div>
      </div>

      <div class="finish-container" v-if="!dispCost">
        <el-button size="large" type="primary" @click="finishInput">完成</el-button>
      </div>

      <!-- <div class="license-table" v-if="!dispCost && dataList.length > 0">
        <el-table :data="dataList" :header-cell-style="{background: '#e9e9e9'}" @row-click="rowClicked">
            <el-table-column v-for="item in dataColumn" :key="item.key" :prop="item.key" :label="item.label" :align="'center'">
              <template #default="scope">
                <span>{{ scope.row }}</span>
              </template>
            </el-table-column>
        </el-table>
      </div> -->

      <div class="cost-container" v-if="dispCost">
        <span>停车费</span>
        <span class="cost-num">￥{{ cost }}</span>
        <div class="divide-line" />
        <div class="cost-detail">
          <div class="detail-container">
            <span>已停时长</span>
            <span>{{ durationDesc }}</span>
          </div>

          <div class="detail-container">
            <span>入场时间</span>
            <span>{{ startTime }}</span>
          </div>

          <!-- <div class="detail-container">
            <span>车牌号码</span>
            <span>{{ license }}</span>
          </div> -->

          <div class="detail-container">
            <span>停车位置</span>
            <span>{{ parkName }}</span>
          </div>
        </div>
      </div>

      <div class="pay-container" v-if="dispCost">
        <el-button size="large" type="primary" @click="pay">支付</el-button>
      </div>
    </div>
    <div class="footer-container">
      <a target="_blank" href=" " @click="link" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <p style="height:20px;line-height:20px; color:#939393;">沪ICP备20016289号-2
        </p>
      </a>
    </div>
    <license-keyboard class="license-input" v-show="dispLicenseInput" @key-tapped="keyboardTapped"
      @check-tapped="keyboardChecked" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, nextTick } from 'vue';
import { getAction, postAction } from '@/utils/manage';
import { getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import LicenseKeyboard from '@/components/LicenseKeyboard.vue';
import { ElMessage, ElLoading } from 'element-plus'

const { proxy } = getCurrentInstance()
const route = useRoute()
const router = useRouter()

const cost = ref('0.0');
const duration = ref('-');
const startTime = ref('-');
const parkId = ref('-');
const openId = ref("");
const appId = ref("");
const dispCost = ref(false);
const enterRecordId = ref("");
let license = '';
let parkIdParam = ''

const licenseList = reactive([
  { value: '浙', ref: 'License0' },
  { value: 'C', ref: 'License1' },
  { value: '', ref: 'License2' },
])

const dispLicenseInput = ref(false)
function pay() {
  const loading = ElLoading.service({
    lock: true,
    background: 'rgba(0, 0, 0, 0.7)',
  })
  let isWeiXin = false;
  const ua = window.navigator.userAgent.toLowerCase();
  isWeiXin = (ua.match(/MicroMessenger/i) == 'micromessenger');
  // isWeiXin = false;
  if (isWeiXin) {
    const param = {
      plateNumber: license,
      tradeType: 'JSAPI',
      subOpenid: openId.value,
      subAppid: appId.value,
      orderAmt: cost.value * 100,
      parkId: parkId.value,
      enterRecordId: enterRecordId.value,
      parkingTime: duration.value
    }
    // alert(JSON.stringify(param))
    postAction('/fy/order/create', param).then(res => {
      loading.close()
      if (res.code === 200) {
        return res.data
      }
    }).then(result => {
      // alert(JSON.stringify(result))
      const { sdk_timestamp: timeStamp, sdk_noncestr: nonceStr, sdk_paysign: sign, sdk_signtype: signType, sdk_package, sdk_appid } = result
      const data = {
        'appId': sdk_appid,
        'timeStamp': timeStamp,
        'nonceStr': nonceStr,
        'package': sdk_package,
        'signType': signType,
        'paySign': sign
      }
      // alert(JSON.stringify(data))
      onBridgeReady(data)
    })
  } else {
    const param = {
      plateNumber: license,
      tradeType: 'FWC',
      subAppid: '',
      subOpenid: route.query.userId,
      orderAmt: cost.value * 100,
      parkId: parkId.value,
      enterRecordId: enterRecordId.value,
      parkingTime: duration.value
    }

    // alert(JSON.stringify(param))
    postAction('/fy/order/create', param).then(res => {
      // alert(JSON.stringify(res))
      loading.close()
      if (res.code === 200) {
        return res.data
      }
    }).then(result => {
      if (result.reserved_transaction_id) {
        aliPayment(result.reserved_transaction_id)
      }
    })
  }
}

const durationDesc = ref('')
const parkName = ref('')
function finishInput() {

  license = licenseList.map(item => item.value).join('')
  const licenseLength = isElectricity.value ? 8 : 7
  if (license.length < licenseLength) {
    ElMessage({
      showClose: false,
      message: '请输入完整的车牌号。',
      type: 'error',
      customClass: 'cost-massage'
    })
    return;
  }
  const loading = ElLoading.service({
    lock: true,
    background: 'rgba(0, 0, 0, 0.7)',
  })
  // alert(parkIdParam)
  getAction('park/bill/info', { plateNumber: license, parkId: parkIdParam }).then(res => {
    // alert(JSON.stringify(res))
    loading.close()
    if (res.code === 200) {
      durationDesc.value = res.data.durationDesc
      duration.value = res.data.duration;
      parkId.value = res.data.parkId;
      parkName.value = res.data.parkName
      license = res.data.plateNumber;
      cost.value = res.data.orderAmt / 100;
      dispCost.value = true;
      startTime.value = res.data.startTime;
      enterRecordId.value = res.data.enterRecordId
    } else if (res.code === 111) {
      router.push({ path: '/success', query: { message: res.message } })
    } else {
      ElMessage({
        showClose: false,
        message: res.message,
        type: 'error',
        customClass: 'cost-massage'
      })
    }
  })
}

function onBridgeReady(data) {
  WeixinJSBridge.invoke(
    'getBrandWCPayRequest', { // 下面参数内容都是后台返回的
    'appId': data.appId, // 公众号名称，由商户传入
    'timeStamp': data.timeStamp, // 时间戳
    'nonceStr': data.nonceStr, // 随机串
    'package': data.package, // 预支付id
    'signType': data.signType, // 微信签名方式
    'paySign': data.paySign // 微信签名
  },
    function (res) {
      // alert(JSON.stringify(res))
      // alert(JSON.stringify(res));
      // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
      if (res.err_msg === 'get_brand_wcpay_request:ok') {
        router.push({ path: '/success' })
      }
    }
  )
}

function aliPayment(tradeNO) {
  const aliPay = proxy.$aliPay
  aliPay.call("tradePay", {
    tradeNO: tradeNO
  }, function (data) {
    if ("9000" == data.resultCode) {
      router.push({ path: '/success' })
    }
  });
}

const isElectricity = ref(true)
function energyTypeChange() {
  licenseList[2].value = ''
}
function dispLicenseKeyboard() {
  if (dispCost.value) {
    return
  }
  document.activeElement.blur();
  dispLicenseInput.value = true;
}
function keyboardTapped(val) {
  licenseList[0].value = val;
}
function keyboardChecked() {
  dismissKeyboard();
  autoFocusNext()
}
function dismissKeyboard() {
  dispLicenseInput.value = false;
}
function inputChange(i) {
  licenseList[i].value = licenseList[i].value.toUpperCase()
  autoFocusNext()
}
function autoFocusNext() {
  let emptyIndex = -1;
  for (const index in licenseList) {
    const val = licenseList[index].value;
    if (val === '' || val === undefined) {
      emptyIndex = parseInt(index);
      break;
    }
  }
  const item = licenseList[emptyIndex]
  if (emptyIndex === 0) {
    document.activeElement.blur();
    dispLicenseInput.value = true;
  } else {
    if (item) {
      nextTick(() => {
        item[item.ref].focus()
      })
    }
  }
}

function setItemRef(ele, key) {
  if (ele) {
    licenseList.map(item => {
      if (item.ref === key) {
        item[key] = ele
      }
    })
  }
}

function getMaxLength() {
  return isElectricity.value ? 6 : 5
}

// const dataList = reactive([])
// const dataColumn = [
//   { key: 'license', label: '车牌号' }
// ]
function initLicenseData() {
  getAction(`playBackPlateNumber/${parkIdParam}`).then(res => {
    // alert(JSON.stringify(res))
    if (res.code === 200) {
      if (res.data) {
        licenseList.splice(0, licenseList.length)
        const splitLicense = res.data.plateNumber.split('')
        licenseList.push({ value: splitLicense[0], ref: 'License0' })
        licenseList.push({ value: splitLicense[1], ref: 'License1' })
        licenseList.push({ value: splitLicense.splice(2, splitLicense.length - 2).join(''), ref: 'License2' })
      }
    }
  })
}

// function rowClicked(row) {
//   console.log(row)
// licenseList.splice(0, licenseList.length)
// const splitLicense = row.split('')
// licenseList.push({ value: splitLicense[0], ref: 'License0' })
// licenseList.push({ value: splitLicense[1], ref: 'License1' })
// licenseList.push({ value: splitLicense.splice(2, splitLicense.length-2).join(''), ref: 'License2' })
// }

onMounted(() => {
  const geturl = window.location.href;
  const urlParam = geturl.split('?');
  if (urlParam.length > 1) {
    const param = urlParam[1];
    const params = new URLSearchParams(param);
    openId.value = params.get('openid');
    appId.value = params.get('appid');
    parkIdParam = params.get('parkId');
  }
  autoFocusNext()
  initLicenseData()
})

function link() {
  window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
}
</script>

<style lang="scss" scoped>
.main-container {
  background: #f7f7f7;
  height: 100vh;

  .content-container {
    height: calc(100% - 20px);

    .license-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 20px 20px 10px 20px;
      border: 1px solid #d3d3d3;
      background-color: white;

      >div:first-child {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .license-input-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .license-area {
          width: 38px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          font-size: 18px;
          // border: 1px solid var(--el-border-color);
          box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
          border-radius: var(--el-border-radius-base);
        }

        .license-area-focus {
          box-shadow: 0 0 0 1px var(--el-color-primary) inset;
        }

        .el-input {
          width: 38px;
          height: 38px;
          text-align: center;
          font-size: 18px;
        }

        .license-num {
          width: calc(100% - 38px - 38px - 5px);

          :deep(.el-input__inner) {
            text-align: left;
            font-size: 18px;
            letter-spacing: 1.5em;
          }
        }

        .el-input.is-disabled {
          :deep(.el-input__wrapper) {
            box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
            background-color: var(--el-input-bg-color, var(--el-fill-color-blank));

            .el-input__inner {
              color: var(--el-text-color-primary);
              -webkit-text-fill-color: var(--el-text-color-primary);
            }
          }
        }

        :deep(.el-input__inner) {
          text-align: center;
        }
      }

      .el-button {
        margin-left: 10px;
      }
    }

    .license-tool {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    .finish-container {
      margin-top: 10px;
      padding: 0 20px;

      .el-button {
        width: 90vw;
      }
    }

    .cost-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0;
      border: 1px solid lightgray;
      background-color: white;
      margin-top: 20px;

      .cost-num {
        font-weight: 900;
        font-size: 28px;
        margin-top: 20px;

      }

      .divide-line {
        width: 90%;
        height: 1px;
        background-color: lightgray;
        margin: 20px 0;
      }

      .cost-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 90%;

        .detail-container {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    .pay-container {
      margin-top: 20px;
      padding: 0 20px;

      .el-button {
        width: 90vw;
      }
    }
  }

  .license-table {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    height: calc(100vh - 153px - 10px - 40px - 12px - 20px);

    .el-table {
      width: 90vw;
      height: 100%;
    }
  }

  .footer-container {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 0;
  }
}
</style>
